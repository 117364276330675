import type { loader } from '#app/root';

import { PageInfo } from '#app/hooks/page-info';
import { cn } from '#app/utils/misc';
import { NavLink, useMatches, useParams, useRouteLoaderData } from '@remix-run/react';
import { cva } from 'class-variance-authority';
import React from 'react';

import { UploadBookDialog } from './upload-book-dialog';

const Sidebar: React.FC = () => {
  const root = useRouteLoaderData<typeof loader>('root');
  const params = useParams();
  
  return (
    <aside className='pt-12 pl-2.5 pr-5 grid grid-flow-row items-center auto-rows-max gap-5'>
      <div>
        {root?.user 
          ? Object.values(PageInfo)
            .map((value) => {
              if(typeof value.url === 'function' && root.user) {
                return (
                  <SidebarItem
                    active={root.user.id === Number.parseInt(params.userId ?? '0', 10)}
                    activeKey={value.activeKey}
                    icon={value.icon}
                    key={value.key}
                    name={value.name}
                    to={value.url(root.user.id)}
                  />
                );
              }
              return (
                <SidebarItem
                  activeKey={value.activeKey}
                  icon={value.icon}
                  key={value.key}
                  name={value.name}
                  to={value.url as string}
                />
              );
            })
          : Object.values(PageInfo)
            .filter(v => v.loggedIn === false)
            .map((value) => <SidebarItem
              activeKey={value.activeKey}
              icon={value.icon}
              key={value.key}
              name={value.name}
              to={value.url as string}
            />)}
          
      </div>
      {!root?.user 
        ? null
        : (
          <UploadBookDialog type='sidebar' />
        )
      }
    </aside>
  );
};

const itemVariants = cva(
  'cursor-pointer grid grid-flow-col justify-start gap-3.5 px-2.5 py-3 items-center rounded-md',
  {
    defaultVariants: {
      isActive: 'paused',
    },
    variants: {
      isActive: {
        'false' : 'bg-transparent [&>h1]:hover:animate-sidebar-item-in [&>h1]:animate-sidebar-item-out',
        'paused': 'bg-transparent paused',
        'true'  : 'bg-gray-20'
      },
    },
  },
);

interface SidebarItemProps{
  active?: boolean;
  activeKey: string;
  icon: (isActive: boolean)=>React.ReactNode;
  name: string;
  to: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ active, activeKey, icon, name, to }) => {
  const match = useMatches();
  const isActive = active ?? match.find(m => m.id === activeKey);
  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {
    if (hover === false) { 
      return;
    }
    void setHover(state => !state);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);


  return (
    <NavLink className={() => {
      return isActive ? 'text-label-2-bold xs:font-sfProBold sm:font-pretendardBold pointer-events-none' : 'text-label-2-medium xs:font-sfProMedium sm:font-pretendardMedium';
    }} 
    prefetch='intent'
    to={to}
    >
      {() => {
        return <section
          className={
            cn(
              itemVariants({
                isActive: 
                  hover === true 
                    ? !!isActive
                    : isActive 
                      ? true
                      : 'paused'
              }))
          } 
          onMouseOver={() => {
            if (hover === false) {
              setHover(true);
            }
          }}
        >
          {icon(!!isActive)}
          <h1 className={'text-gray-90'}>{name}</h1>
        </section>;   
      }}
    </NavLink>
  );
};

export default Sidebar;