/* eslint-disable complexity */
import type { Keyword } from '@prisma/client';
import type {
  ShouldRevalidateFunctionArgs } from '@remix-run/react';

import { UserServer } from '#app/utils/user/user.server';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction } from
'@remix-run/node';
import {
  json } from
'@remix-run/node';
import {
  Outlet,
  useLoaderData,
  useMatch,
  useNavigate,
  useSearchParams } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';

import { CongratuFirstUploadDialog } from './components/desktop/congratu-first-upload-dialog.tsx';
import OnboardingDialog from './components/desktop/onboarding-dialog.tsx';
import SignupDialog from './components/desktop/signup-dialog.tsx';
import Document from './components/document.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import Responsive from './components/responsive.tsx';
import { useToast } from './components/toaster.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { Toaster } from './components/ui/sonner.tsx';
import useParticles from './hooks/use-particles.tsx';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { getHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { dialogSessionStorage } from './utils/dialog.server.ts';
import { getEnv } from './utils/env.server.ts';
import { GlobalDialog } from './utils/global-dialog.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { KeywordServer } from './utils/keyword/keyword.server.ts';
import { LibraryServer } from './utils/library/library.server.ts';
import { logger } from './utils/logger.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { getToast } from './utils/toast.server.ts';
import { getOnboardingStep } from './utils/user/user.ts';
export { rootAction as action } from './root-action.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { as: 'image', href: iconsHref, rel: 'preload' },
  // Preload CSS as a resource to avoid render blocking
  { as: 'style', href: tailwindStyleSheetUrl, rel: 'preload' },
  cssBundleHref ? { as: 'style', href: cssBundleHref, rel: 'preload' } : null,
  { href: '/favicons/mask-icon.svg', rel: 'mask-icon' },
  {
    href: '/favicons/favicon-32x32.png',
    rel: 'alternate icon',
    type: 'image/png'
  },
  { href: '/favicons/apple-touch-icon.png', rel: 'apple-touch-icon' },
  //These should match the css preloads above to avoid css as render blocking resource
  {
    crossOrigin: 'use-credentials',
    href: '/site.webmanifest',
    rel: 'manifest'
  } as const,
  { href: '/favicons/favicon.svg', rel: 'icon', type: 'image/svg+xml' },
  { href: tailwindStyleSheetUrl, rel: 'stylesheet' },
  cssBundleHref ? { href: cssBundleHref, rel: 'stylesheet' } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? '북커버리' : 'Error | 북커버리' },
  { content: '요즘 일하는 사람들이 책을 찾는 새로운 방법', name: 'description' }];

};
export async function loader({ request }: LoaderFunctionArgs) {
  try {
    logger.info('root ------- loader START');
    const userId = await getUserId(request);

    const user = userId ?
    await UserServer.getUserByRoot(userId) :
    null;


    if (userId && !user) {
      await logout({ redirectTo: '/open-library', request });
    }

    const { headers: toastHeaders, toast } = await getToast(request);
    const honeyProps = honeypot.getInputProps();
    const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

    const [
    groupedKeyword,
    careerKeywords,
    librarySize] =
    await Promise.all([
    KeywordServer.getGroupKeywords(),
    KeywordServer.getKeywordByGroupName('career'),
    LibraryServer.getBookcoveryLibrarySize() ?? 0]
    );


    if (!user) {
      return json({
        ENV: getEnv(),
        csrfToken,
        groupedKeyword,
        honeyProps,
        keywords: careerKeywords,
        librarySize,
        requestInfo: {
          hints: getHints(request),
          origin: getDomainUrl(request),
          path: new URL(request.url).pathname
        },
        toast,
        user: null
      },
      {
        headers: combineHeaders(
          toastHeaders,
          csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
        )
      });
    }
    logger.info('root ------- loader END');

    const onboardingStep = getOnboardingStep({
      careerKeyword: user.careerKeyword,
      companyHistory: user.companyHistory,
      followKeywords: user.followKeywords,
      workingCompanyName: user.workingCompanyName!
    });

    const verifiedCompanyHistory = user?.companyHistory.find((ch) => ch.verified === true);
    const verifiedCompany = verifiedCompanyHistory?.company;
    const dialogSession = await dialogSessionStorage.getSession(
      request.headers.get('cookie')
    );
    const firstUpload = dialogSession.get('first-upload') === true;

    return json(
      {
        ENV: getEnv(),
        csrfToken,
        groupedKeyword,
        honeyProps,
        keywords: careerKeywords,

        librarySize,

        onboardingStep: !onboardingStep ? 'DONE' : onboardingStep,
        requestInfo: {
          hints: getHints(request),
          origin: getDomainUrl(request),
          path: new URL(request.url).pathname
        },
        toast,
        user: {
          ...user,
          firstUpload,
          verifiedCompany: verifiedCompany ?
          {
            ...verifiedCompany,
            createdAt: verifiedCompanyHistory?.createdAt,
            email: verifiedCompanyHistory?.email,
            id: verifiedCompanyHistory?.id,
            verified: verifiedCompanyHistory?.verified,
            verifiedAt: verifiedCompanyHistory?.verifiedAt
          } :
          undefined
        }
      },
      {
        headers: combineHeaders(
          toastHeaders,
          new Headers({
            'set-cookie': await dialogSessionStorage.destroySession(dialogSession)
          }),
          csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
        )
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};


export const shouldRevalidate = ({ formMethod }: ShouldRevalidateFunctionArgs) => {
  if (formMethod === 'POST') {
    return true;
  }
  return false;
};

function App() {
  const data = useLoaderData<typeof loader>();
  const [searchParams, setSearchParams] = useSearchParams();
  const nonce = useNonce();
  const skipOnboarding = useMatch({ path: '/company/*' });
  const navigate = useNavigate();
  void useToast(data.toast);

  React.useEffect(() => {
    if (!isMobile) {
      if ('onboardingStep' in data) {
        const params = new URLSearchParams();
        if (data.onboardingStep === 'DONE') {
          params.delete(GlobalDialog.Onboarding);
          params.delete(GlobalDialog.OnboardingStep);
        } else {
          searchParams.forEach((value, key) => {
            params.set(key, value);
          });
          params.set(GlobalDialog.Onboarding, '');
          params.set(GlobalDialog.OnboardingStep, data.onboardingStep);
          void setSearchParams(params);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isMobile && 'onboardingStep' in data) {
      if (data.onboardingStep === 'DONE') {
        return;
      }
      navigate(`/signup/${data.onboardingStep}`);
      return;
    }
    if (isMobile && searchParams.get(GlobalDialog.Onboarding) === '') {
      navigate('/signup/profile');
    }
  }, []);

  const Particles = useParticles();

  return (
    <Document env={data.ENV} nonce={nonce}>
      <Responsive>
        <Outlet />
      </Responsive>
      <Toaster expand />
      {searchParams.get(GlobalDialog.Signup) === '' && <SignupDialog />}
      {skipOnboarding === null && searchParams.get(GlobalDialog.Onboarding) === '' && !isMobile &&
      <OnboardingDialog
        careerKeyword={data.user?.careerKeyword as unknown as Keyword}
        companyName={data.user?.workingCompanyName}
        email={data.user?.companyHistory?.find((h) => h.verified === false && h.email)?.email ?? data.user?.verifiedCompany?.email ?? ''}
        followKeywords={data.user?.followKeywords?.map((fk) => ({
          ...fk.keyword,
          createdAt: new Date(fk.createdAt),
          updatedAt: new Date(fk.updatedAt)
        })) || []}
        groupedKeyword={data.groupedKeyword as unknown as Record<string, Keyword[]>}
        imageUrl={data.user?.imageUrl || ''}
        keywords={data.keywords}
        name={data.user?.email || ''} />}


      {data.user?.firstUpload === true &&
      <>
          {Particles}
          <CongratuFirstUploadDialog rootData={data} />
        </>}

    </Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    /** CSRF 방지 */
    <AuthenticityTokenProvider token={data.csrfToken}>
      {/* 스팸 방지 */}
      <HoneypotProvider {...data.honeyProps}>
        <App />
      </HoneypotProvider>
    </AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>);

}